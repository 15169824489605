import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const timeline_s2 = (el, parent, props) => {
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.matchMedia({
		"(min-width: 80rem)": () => {
			// desktop screens
			console.log("desktop anim");

			gsap.set(el(".section__graph-y-copy"), {autoAlpha: 0})
			gsap.set(el(".section__graph-copy"), {autoAlpha: 0, xPercent: 100})
			gsap.set(el(".copy-y-1"), {autoAlpha: 1})
			gsap.set(el(".copy-1"), {autoAlpha: 1, xPercent: 0})
			gsap.set(el(".graph-decrease .line"), {autoAlpha: 0})
			gsap.set(el(".graph-6 .section__graph-copy"), {autoAlpha: 0})
			gsap.set(el(".graph-6 .section__graph"), {autoAlpha: 0})
			gsap.set(el(".graph-6 .section__graph-copy.copy-6"), {xPercent: -100})
			gsap.set(el(".graph-6 .section__graph-copy.copy-7"), {xPercent: 100})


			// gsap.set(el(".graph-6 .section__graph-text-wrapper"), {autoAlpha: 0})
			// gsap.set(el(".graph-6 .section__graph-copy-container"), {yPercent: 100, autoAlpha: 0})

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: el(".graph-1"),
					markers: false,
					id: "graphs",
					pin: true,
					scrub: true,
					start: "top top",
					end: () => `+=${window.innerHeight * el(".section__graph-copy").length}`
				},
			});

			tl
				.to(el(".copy-y-1"), {autoAlpha: 0})
				.to(el(".copy-1"), {autoAlpha: 0}, "-=0.5")
				.to(el(".graph-increase"), {autoAlpha: 0}, "-=0.5")
			tl
				.to(el(".copy-y-2"), {autoAlpha: 1})
				.to(el(".copy-2"), {autoAlpha: 1, xPercent: 0}, "-=0.5")
				.to(el(".graph-decrease .line"), {autoAlpha: 1}, "-=0.5")
			tl
				.to(el(".copy-y-2"), {autoAlpha: 0})
				.to(el(".copy-2"), {autoAlpha: 0}, "-=0.5")
				.to(el(".graph-decrease .line"), {autoAlpha: 0}, "-=0.5")
			tl
				.to(el(".copy-y-3"), {autoAlpha: 1})
				.to(el(".copy-3"), {autoAlpha: 1, xPercent: 0}, "-=0.5")
				.to(el(".graph-increase"), {autoAlpha: 1}, "-=0.5")
			tl
				.to(el(".copy-y-3"), {autoAlpha: 0}, )
				.to(el(".copy-3"), {autoAlpha: 0, xPercent: 0}, "-=0.5")
			tl
				.to(el(".copy-y-4"), {autoAlpha: 1}, )
				.to(el(".copy-4"), {autoAlpha: 1, xPercent: 0}, "-=0.5")
			tl
				.to(el(".copy-y-4"), {autoAlpha: 0}, )
				.to(el(".copy-4"), {autoAlpha: 0, xPercent: 0}, "-=0.5")
			tl
				.to(el(".copy-y-5"), {autoAlpha: 1}, )
				.to(el(".copy-5"), {autoAlpha: 1, xPercent: 0}, "-=0.5")
	
			const tl_2 = gsap.timeline({
				scrollTrigger: {
					trigger: el(".graph-6"),
					markers: false,
					id: "graph-6",
					pin: true,
					scrub: true,
					start: "top top",
				}
			})
			.to(el(".graph-6 .section__graph-copy.copy-6"), {autoAlpha: 1, xPercent: -10})
			.to(el(".graph-6 .section__graph"), {autoAlpha: 1})
			.to(el(".graph-6 .section__graph-copy.copy-7"), {autoAlpha: 1, xPercent: 0})

			gsap.timeline({
				scrollTrigger: {
					trigger: parent,
					start: "top +=120",
					scrub: true,
					pin: false,
					onEnter: () => props.setLogoLight(true),
					onLeaveBack: () => props.setLogoLight(false),
				},
			});
			ScrollTrigger.defaults({
				anticipatePin: 1,
			});
		},
		"(max-width: 80rem)": () => {
			////////////////////
			// tablet and mob //
			////////////////////
			console.log("tablet and mobile anim");
		},
	});
};

export default timeline_s2;
