import React from "react";
import {motion} from "framer-motion"
import {gsap} from "gsap/dist/gsap";
import timeline from "./timeline";
import Shape from "../../Shape";
import person from "../../../assets/images/person.png";
import flowChart from "../../../assets/images/flow-chart.svg";
import icon1 from "../../../assets/images/shape-1.svg";
import icon2 from "../../../assets/images/shape-2.svg";
import icon3 from "../../../assets/images/shape-3.svg";
import icon4 from "../../../assets/images/shape-4.svg";
import icon5 from "../../../assets/images/shape-5.svg";
import icon6 from "../../../assets/images/shape-6.svg";
// import circle from "../../../assets/images/shape-1.svg";
// import triangle from "../../../assets/images/shape-2.svg";
// import square from "../../../assets/images/shape-3.svg";
// import cross from "../../../assets/images/shape-4.svg";
// import star from "../../../assets/images/shape-5.svg";

const Flow = (props, ref) => {
	const wrapperRef = React.useRef(null);
	const q = gsap.utils.selector(wrapperRef);

	React.useEffect(() => {
		timeline(q, wrapperRef.current, props);
	}, []);

	// 	const shapes = [
	// 	{id: 1, src: circle.src, alt: ""},
	// 	{id: 2, src: triangle.src, alt: "Green outline of a triangle"},
	// 	{id: 3, src: square.src, alt: "Blue outline of a square"},
	// 	{id: 4, src: cross.src, alt: "Red cross"},
	// 	{id: 5, src: star.src, alt: "Yellow outline of a star"},
	// 	{id: 6, src: hexagon.src, alt: "Grey outline of a hexagon"}
	// ]

	return (
			<div className="section__flow-wrapper grid light-bg" ref={wrapperRef}>
			<div className="section__column col-12 col-4@lg copy-1">
				<p className="section__copy">We can also use emotor to identify future opportunities and diagnose future problems.</p>
			</div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
				<div className="section__column section__flow-chart-container flow-chart grid">
					<img src={person.src} alt="A person icon" className="col-start-1" style={{ gridRowStart: 1 }}/>
					<svg xmlns="http://www.w3.org/2000/svg" className="col-start-3" style={{ gridRowStart: 1, maxWidth: "400px" }} width="712.925" height="299" viewBox="0 0 712.925 299">
					<g id="Group_62" data-name="Group 62" transform="translate(-635.075 -406)">
						<path id="Union_6" data-name="Union 6" d="M13,105.482V26H0L15,0,30,26H17v79.482Z" transform="translate(725.49 459.958) rotate(59)" fill="#292c39"/>
						<path id="Union_14" data-name="Union 14" d="M13,72.481V26H0L15,0,30,26H17V72.481Z" transform="translate(986.513 414.955) rotate(59)" fill="#292c39"/>
						<path id="Union_17" data-name="Union 17" d="M13,72.481V26H0L15,0,30,26H17V72.481Z" transform="translate(986.513 583.716) rotate(59)" fill="#292c39"/>
						<path id="Union_15" data-name="Union 15" d="M13,0V46.482H0l15,26,15-26H17V0Z" transform="translate(924.384 492.192) rotate(-59)" fill="#292c39"/>
						<path id="Union_16" data-name="Union 16" d="M13,0V46.482H0l15,26,15-26H17V0Z" transform="translate(924.384 660.953) rotate(-59)" fill="#292c39"/>
						<path id="Union_12" data-name="Union 12" d="M13,0V79.482H0l15,26,15-26H17V0Z" transform="translate(635.075 600.673) rotate(-59)" fill="#292c39"/>
						<rect id="Rectangle_78" data-name="Rectangle 78" width="150" height="60" rx="30" transform="translate(761 440)" fill="#009fdf"/>
						<rect id="Rectangle_79" data-name="Rectangle 79" width="150" height="60" rx="30" transform="translate(761 611)" fill="#009fdf"/>
						<text id="Buy" transform="translate(836 479)" fill="#fff" fontSize="24" fontFamily="Montserrat" fontWeight="700"><tspan x="-24" y="0">Buy</tspan></text>
						<text id="Don_t_buy" data-name="Don’t buy" transform="translate(836 650)" fill="#fff" fontSize="24" fontFamily="Montserrat" fontWeight="700"><tspan x="-61" y="0">Don’t buy</tspan></text>
						<g id="flow_button" data-name="flow button" transform="translate(1022.097 406)">
							<rect id="Rectangle_80" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#009fdf"/>
							<text id="Motivation" transform="translate(75 26)" fill="#fff" fontSize="16" fontFamily="Montserrat" fontWeight="700"><tspan x="-45" y="0">Motivation</tspan></text>
						</g>
						<g id="flow_button-2" data-name="flow button" transform="translate(1192 424)">
							<rect id="Rectangle_80-2" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#00c87d"/>
							<text id="Emotor" transform="translate(75 26)" fill="#fff" fontSize="16" fontFamily="Montserrat" fontWeight="700"><tspan x="-31" y="0">Emotor</tspan></text>
						</g>
						<g id="flow_button-3" data-name="flow button" transform="translate(1198 593)">
							<rect id="Rectangle_80-3" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#00c87d"/>
							<text id="Emotor-2" data-name="Emotor" transform="translate(75 26)" fill="#fff" fontSize="16" fontFamily="Montserrat" fontWeight="700"><tspan x="-31" y="0">Emotor</tspan></text>
						</g>
						<g id="flow_button-4" data-name="flow button" transform="translate(1022.097 500)">
							<rect id="Rectangle_80-4" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#009fdf"/>
							<text id="Frequency" transform="translate(75 26)" fill="#fff" fontSize="16" fontFamily="Montserrat" fontWeight="700"><tspan x="-44" y="0">Frequency</tspan></text>
						</g>
						<g id="flow_button-5" data-name="flow button" transform="translate(1192 480)">
							<rect id="Rectangle_80-5" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#f05a4c"/>
							<text id="Emotor-3" data-name="Emotor" transform="translate(75 26)" fill="#fff" fontSize="16" fontFamily="Montserrat" fontWeight="700"><tspan x="-31" y="0">Emotor</tspan></text>
						</g>
						<g id="flow_button-6" data-name="flow button" transform="translate(1198 649)">
							<rect id="Rectangle_80-6" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#f05a4c"/>
							<text id="Emotor-4" data-name="Emotor" transform="translate(75 26)" fill="#fff" fontSize="16" fontFamily="Montserrat" fontWeight="700"><tspan x="-31" y="0">Emotor</tspan></text>
						</g>
						<g id="flow_button-7" data-name="flow button" transform="translate(1022.097 573)">
							<rect id="Rectangle_80-7" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#009fdf"/>
							<text id="Reasons" transform="translate(75 26)" fill="#fff" fontSize="16" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="-35" y="0">Reasons</tspan></text>
						</g>
						<g id="flow_button-8" data-name="flow button" transform="translate(1022.097 665)">
							<rect id="Rectangle_80-8" data-name="Rectangle 80" width="150" height="40" rx="20" fill="#009fdf"/>
							<text id="Future_behaviour" data-name="Future
				behaviour" transform="translate(75 18)" fill="#fff" fontSize="16" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="-27" y="0">Future</tspan><tspan x="-42" y="16">behaviour</tspan></text>
						</g>
					</g>
				</svg>

				</div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg copy-2">
				<p className="section__copy">
					Based on purchase behaviour we can see where emotional capital is low amongst existing customers, and where it is high amongst non-customers.
					Providing a defend and attack strategy for growth focused.
				</p>
			</div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column section__pin-column col-12 col-4@lg">
				<div className="intro__image-container no-bg">
					<img className="intro__person" src={person.src} />
					<div className="intro__icon-container">
						<img className="intro__icons icon-1 icon-green" src={icon1.src} />
						<img className="intro__icons icon-2 icon-red" src={icon2.src} />
						<img className="intro__icons icon-3 icon-red" src={icon3.src} />
						<img className="intro__icons icon-4 icon-green" src={icon4.src} />
						<img className="intro__icons icon-5 icon-red" src={icon5.src} />
						<img className="intro__icons icon-6 icon-green" src={icon6.src} />
					</div>
				</div>
			</div>
			{/* <div className="section__column section__pin-column col-12 col-4@lg">
				<div className="section1__image-container">
					<img className="section1__person" src={person.src} />
						<div className="shape__layout-container" style={{zIndex: 1}}>		
							<motion.div className="shape__items-container">
								{shapes.map(s => (
									<Shape key={s.id} id={s.id} src={s.src} alt={s.alt} />
								))}
							</motion.div>
						</div>
				</div>
			</div> */}
					<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			{/* next row - icons have red border (star, circle and square)*/}
			<div className="section__column col-12 col-4@lg copy-3">
				<p className="section__copy">
					We can map your category to show which brands have low levels of Emotional Capital
				</p>
			</div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			{/* next row - icons have green border (hexagon, triangle, and cross)*/}
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg copy-4">
				<p className="section__copy">
					And which brands have high levels of Emotional Capital
				</p>
			</div>
			{/* next row - fade out the icons that do not have a green border */}
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg copy-5">
				<p className="section__copy">
					That are helping them build closer customer relationships
				</p>
			</div>
		</div>
	);
};

export default Flow;