import React from "react";
import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import timeline_hero from "./timeline";

const Hero = () => {
	gsap.registerPlugin(ScrollTrigger);
	// ref for parent to use gsap utility to
	//target class selectors within this element
	const wrapperRef = React.useRef(null);
	const q = gsap.utils.selector(wrapperRef);

	React.useEffect(() => {
		timeline_hero(q, wrapperRef.current);
	}, []);

	return (
		<div className="hero__wrapper" ref={wrapperRef}>
			<h1 className="hero__title">
				<span className="hero__title-line-1">How We Calculate</span>
				<br />
				<span className="hero__title-line-2">Emotional Capital</span>
			</h1>
		</div>
	);
};

export default Hero;
