import React from "react";

const Contact = () => (
		<section id="getInTouch" className="contact layout__section-flex">
			<h1 className="contact__heading">
				<span className="contact__heading-line-1">get</span>
				<br/>
				<span className="contact__heading-line-2">in touch</span>
			</h1>
			<div className="contact__details-wrapper">
				<div className="contact__details">
					<h2>Kate Howe</h2>
					<h4>Executive Director</h4>
					<a href="mailto:kate.howe@msqpartners.com">kate.howe@msqpartners.com</a>
					<a href="tel:+447920810501">+44 (0)7920 810501</a>
				</div>
				<address className="contact__address">
					MSQ
					<br/>34 Bow Street
					<br/>London
					<br/>WC2E 7AU
				</address>
				<div className="contact__social">
					<h4>Follow us</h4>
					<ul className="contact__social-list">
						<li><a href="https://uk.linkedin.com/company/msq-partners" title="linkedin" target="_blank" className="social__link" rel="noreferrer"><svg viewBox="0 0 20.93 20" aria-label="linkedin" role="img" focusable="false"><rect x="0.26" y="6.5" width="4.5" height="13.5" className="social__icon"></rect> <path d="M19.51,7.73a4.84,4.84,0,0,0-3.75-1.55,5.41,5.41,0,0,0-1.56.21A3.56,3.56,0,0,0,13,7a5.86,5.86,0,0,0-.77.71,5.73,5.73,0,0,0-.53.72V6.5H7.23v.65q0,.65,0,4t0,8.81h4.48V12.47a3.3,3.3,0,0,1,.15-1.1,2.9,2.9,0,0,1,.87-1.17,2.21,2.21,0,0,1,1.44-.47,1.94,1.94,0,0,1,1.72.81,4,4,0,0,1,.55,2.24V20h4.48V12.26a6.48,6.48,0,0,0-1.42-4.53Zm0,0" className="social__icon"></path> <path d="M2.53,0A2.57,2.57,0,0,0,.7.66,2.19,2.19,0,0,0,0,2.33,2.23,2.23,0,0,0,.68,4a2.46,2.46,0,0,0,1.8.67h0A2.57,2.57,0,0,0,4.35,4,2.16,2.16,0,0,0,5,2.33,2.27,2.27,0,0,0,4.35.66,2.49,2.49,0,0,0,2.53,0Zm0,0" className="social__icon"></path></svg></a></li>
						<li><a href="https://twitter.com/MSQpartners" title="twitter" target="_blank" className="social__link" rel="noreferrer"><svg viewBox="0 0 24.63 20" aria-label="twitter" role="img" focusable="false"><path d="M24.63,2.37a10.1,10.1,0,0,1-2.91.78A4.9,4.9,0,0,0,23.94.38a9.91,9.91,0,0,1-3.2,1.22,5.06,5.06,0,0,0-7.26-.12A4.86,4.86,0,0,0,12,5a5.6,5.6,0,0,0,.12,1.16A14.07,14.07,0,0,1,6.32,4.65,14.3,14.3,0,0,1,1.72.92,5,5,0,0,0,1,3.47a4.92,4.92,0,0,0,.61,2.41,5,5,0,0,0,1.64,1.8A5,5,0,0,1,1,7v.06A4.88,4.88,0,0,0,2.15,10.3,5,5,0,0,0,5,12a5.2,5.2,0,0,1-1.33.17,6.47,6.47,0,0,1-1-.08,5.06,5.06,0,0,0,4.72,3.5A9.88,9.88,0,0,1,1.22,17.8,10.68,10.68,0,0,1,0,17.73,14,14,0,0,0,7.75,20a14.76,14.76,0,0,0,5-.85,12.82,12.82,0,0,0,4-2.28,15.28,15.28,0,0,0,2.88-3.29,14.64,14.64,0,0,0,1.8-3.88,14.33,14.33,0,0,0,.59-4.05c0-.29,0-.51,0-.66a10.58,10.58,0,0,0,2.53-2.61Zm0,0" className="social__icon"></path></svg></a></li>
					</ul>
				</div>
			</div>
		</section>
	);

export default Contact;
