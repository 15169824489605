import react from 'react'
import {motion} from 'framer-motion'

const Background = ({children, onClick}) => {
  return (
    <motion.div
      className="modal__background"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  )
}

export default Background