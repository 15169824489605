import react, {useState, useEffect} from 'react'
import {motion} from 'framer-motion'
import icon from '../../assets/images/downward-triangle.svg'

const ScrollButton = () => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop
      scrolled > 0 ? setVisible(false) : setVisible(true)
    }
    window.addEventListener('scroll', toggleVisible)
    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  }, [])

  return (
    <div className={`scroll__button-wrapper ${visible ? "visible" : "hidden"}`} >
      <motion.button 
        className="scroll__button" 
        initial={{ opacity: 0 }} 
        animate={{ opacity: visible ? 1 : 0 }} 
        transition={{ opacity: { duration: 0.2 } }}
        whileHover={{ scale: 1.1 }}
      >
        Scroll
        <span className="icon">
          <img src={icon.src} alt="downward triangle icon" title="Down facing triangle icon" />
        </span>
      </motion.button>
    </div>
  )
}

export default ScrollButton