import React, {useRef, useState, useEffect} from "react";
import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {motion} from "framer-motion";
import Link from "next/link";
import Button from "../../Button";
import Shape from "../../Shape";
import circle from "../../../assets/images/shape-1.svg";
import triangle from "../../../assets/images/shape-2.svg";
import square from "../../../assets/images/shape-3.svg";
import cross from "../../../assets/images/shape-4.svg";
import star from "../../../assets/images/shape-5.svg";
import hexagon from "../../../assets/images/shape-6.svg";
import playIcon from "../../../assets/images/play-icon.svg";
import variants from "../../../helpers/animation";
import timeline from "./timeline";

const Actions = (props) => {
	// refs
	const wrapperRef = React.useRef(null);
	// register plugin
	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: wrapperRef.current,
				start: "top +=120",
				scrub: true,
				markers: false,
				pin: false,
				onEnter: () => props.setLogoLight(false),
				onLeaveBack: () => props.setLogoLight(true),
			},
		});

		return () => {
			tl.kill();
		};
	}, []);

	// const buttonInfo = [
	// 	{id: 1, text: "Get in touch to get closer to your customers", colorClass: "cyan", path: "#getInTouch", external: false, targetBlank: false},
	// 	{id: 2, text: "Find out how to increase your brand's Emotional Capital at speed", colorClass: "green", path: "https://www.msqpartners.com/", external: true, targetBlank: true},
	// 	{id: 3, text: "Read our emotor study for the UK Grocery category", colorClass: "purple", path: "/MSQ_EC_Retail_Grocers_Report.pdf", external: false, targetBlank: true},
	// ];

	const shapes = [
		{id: 1, src: circle.src, alt: ""},
		{id: 2, src: triangle.src, alt: "Green outline of a triangle"},
		{id: 3, src: square.src, alt: "Blue outline of a square"},
		{id: 4, src: cross.src, alt: "Red cross"},
		{id: 5, src: star.src, alt: "Yellow outline of a star"},
		{id: 6, src: hexagon.src, alt: "Grey outline of a hexagon"},
	];

	return (
		<div id="readOurReports" className="layout__section-flex" ref={wrapperRef}>
			<div className="shape__layout-container">
				<motion.div className="shape__items-container">
					{shapes.map((s) => (
						<Shape key={s.id} id={s.id} src={s.src} alt={s.alt} />
					))}
				</motion.div>
				<div className="button__container">
					<motion.a
						href="https://joinedupthinking.xyz/3d-flip-book/superior-emotional-capital-the-currency-of-closer-customer-relationships-2/"
						id={`button-1`}
						className={`button cyan button-1`}
						whileHover={{scale: 1.02}}
						target="_blank">
						Read our in-depth guide on increasing your brand’s Emotional Capital
					</motion.a>
					<Link href="/MSQ_EC_Retail_Grocers_Report.pdf" scroll={false}>
						<motion.a
							id="button-2"
							href="/MSQ_EC_Retail_Grocers_Report.pdf"
							className={`button green button-2`}
							whileHover={{scale: 1.02}}
							target="_blank">
							Read our emotor study for the UK Grocery category
						</motion.a>
					</Link>
					<Link href="/MSQ_EC_Infant_Food_China_Report.pdf" scroll={false}>
						<motion.a
							id="button-3"
							href="/MSQ_EC_Infant_Food_China_Report.pdf"
							className={`button purple button-3`}
							whileHover={{ scale: 1.02 }}
							target="_blank">
							Read our emotor study for the China Infant Food category
						</motion.a>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Actions;
