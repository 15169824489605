import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const timeline_hero = (el, parent) => {
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.matchMedia({
		"(min-width: 80rem)": () => {
			console.log("desktop");

			gsap.set(".calculating__right-copy", {autoAlpha: 0});
			gsap.set(".calculating__left-copy", {autoAlpha: 0});
			gsap.set(".calculating__title-small", {autoAlpha: 0});
			gsap.set(".calculating__middle-copy", {autoAlpha: 0});
			gsap.set(".middle-copy-3", {autoAlpha: 1});
			gsap.set(".calculating__copy-bottom", {xPercent: 100, autoAlpha: 0});
			gsap.set(".calculating__copy-1", {xPercent: 0, autoAlpha: 1});
			gsap.set(".calculating__image-category", {autoAlpha: 0});

			// gsap.set(el(".calculating__copy"), {xPercent: 100, autoAlpha: 0});
			// gsap.set(el(".calculating__middle-copy"), {autoAlpha: 0});
			// gsap.set(el(".middle-copy-1"), {autoAlpha: 1});
			// gsap.set(el(".middle-copy-3"), {autoAlpha: 1});
			// gsap.set(el(".calculating__copy-1"), {autoAlpha: 1, xPercent: 0});
			// gsap.set(el(".calculating__right-copy"), {autoAlpha: 0});
			// gsap.set(el(".calculating__left-copy"), {autoAlpha: 0});
			// gsap.set(el(".calculating__copy-1"), {xPercent: 0});
			// gsap.set(el(".calculating__image-category"), {autoAlpha: 0});

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: parent,
					markers: false,
					id: "hero-wrapper-pin",
					pin: true,
					scrub: true,
					start: "top top",
					snap: {
						snapTo: "labels",
						directional: true,
						duration: 0.5,
					},
					end: () =>
						`+=${window.innerHeight * el(".calculating__copy").length * 2}`,
				},
			});

			// tl.to(el(".title-copy-1"), {autoAlpha: 1});
			// tl.to(el(".calculating__right-copy.right-copy-1"), {autoAlpha: 1});

			// tl.to(el(".calculating__right-copy.right-copy-1"), {autoAlpha: 0});
			// tl.to(el(".title-copy-1"), {autoAlpha: 0}, "-=0.5");
			// tl.to(el(".calculating__right-copy.right-copy-2"), {autoAlpha: 1});
			// tl.to(el(".title-copy-2"), {autoAlpha: 1}, "-=0.5");

			// tl.to(el(".calculating__copy-1"), {autoAlpha: 0});
			// tl.to(el(".middle-copy-1"), {autoAlpha: 0});

			// tl.to(el(".calculating__copy-2"), {xPercent: 0, autoAlpha: 1});
			// tl.to(el(".middle-copy-2"), {autoAlpha: 1}, "-=0.5");
			// tl.to(el(".calculating__image-1"), {autoAlpha: 1}, "-=0.5");

			tl.to(".title-copy-1", {autoAlpha: 1});
			tl.to(".left-copy-1", {autoAlpha: 1}, "-=0.5");
			tl.to(".right-copy-1", {autoAlpha: 1}, "-=0.5");
			tl.to(".middle-copy-1", {autoAlpha: 1}, "-=0.5");
			tl.to(".calculating__copy-1", {autoAlpha: 1}, "-=0.5");
			tl.addLabel("1");
			tl.to(".title-copy-1", {autoAlpha: 0});
			tl.to(".left-copy-1", {autoAlpha: 0}, "-=0.5");
			tl.to(".right-copy-1", {autoAlpha: 0}, "-=0.5");
			tl.to(".middle-copy-1", {autoAlpha: 0}, "-=0.5");
			tl.to(".title-copy-2", {autoAlpha: 1});
			tl.to(".left-copy-2", {autoAlpha: 1}, "-=0.5");
			tl.to(".right-copy-2", {autoAlpha: 1}, "-=0.5");
			tl.to(".middle-copy-2", {autoAlpha: 1}, "-=0.5");
			tl.addLabel("2");
			tl.to(".left-copy-2", {autoAlpha: 0});
			tl.to(".right-copy-2", {autoAlpha: 0}, "-=0.5");
			tl.to(".calculating__copy-1", {autoAlpha: 0}, "-=0.5");
			tl.addLabel("3");
			// moving bottom copy
			tl.to(el(".calculating__copy-2"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-1"), {autoAlpha: 1}, "-=0.5");
			tl.addLabel("4");

			tl.to(el(".calculating__copy-2"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-1"), {autoAlpha: 0}, "-=0.5");

			tl.to(el(".calculating__copy-3"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-2"), {autoAlpha: 1}, "-=0.5");
			tl.addLabel("5");
			tl.to(el(".calculating__copy-3"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-2"), {autoAlpha: 0}, "-=0.5");
			tl.to(el(".calculating__copy-4"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-3"), {autoAlpha: 1}, "-=0.5");
			tl.addLabel("6");
			tl.to(el(".calculating__copy-4"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-3"), {autoAlpha: 0}, "-=0.5");
			tl.to(el(".calculating__copy-5"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-4"), {autoAlpha: 1}, "-=0.5");
			tl.addLabel("7");
			tl.to(el(".calculating__copy-5"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-4"), {autoAlpha: 0}, "-=0.5");
			tl.to(el(".calculating__copy-6"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-5"), {autoAlpha: 1}, "-=0.5");
			tl.addLabel("8");
			tl.to(el(".calculating__copy-6"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-5"), {autoAlpha: 0}, "-=0.5");
		},
		"(max-width: 80rem)": () => {
			////////////////////
			// tablet and mob //
			////////////////////

			gsap.set(el(".calculating__copy"), {xPercent: 100});
			gsap.set(el(".calculating__copy"), {autoAlpha: 0});
			gsap.set(el(".calculating__copy-1"), {autoAlpha: 1});
			gsap.set(el(".calculating__right-copy"), {autoAlpha: 0});
			gsap.set(el(".calculating__copy-1"), {xPercent: 0});
			gsap.set(el(".calculating__image-category"), {autoAlpha: 0});

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: parent,
					markers: false,
					id: "hero-wrapper-pin",
					pin: true,
					scrub: true,
					start: "top top",
					snap: {
						snapTo: "labels",
						directional: true,
						duration: 0.5,
					},
					end: () =>
						`+=${window.innerHeight * el(".calculating__copy").length * 2}`,
				},
			});

			tl.to(el(".calculating__right-copy.right-copy-1"), {
				autoAlpha: 1,
				duration: 1,
			});

			tl.to(el(".calculating__right-copy.right-copy-1"), {
				autoAlpha: 0,
				duration: 1,
			});
			tl.to(el(".calculating__right-copy.right-copy-2"), {
				autoAlpha: 1,
				duration: 1,
			});

			tl.to(el(".calculating__right-copy.right-copy-2"), {
				autoAlpha: 0,
				duration: 1,
			});
			tl.to(el(".calculating__right-copy.right-copy-3"), {
				autoAlpha: 1,
				duration: 1,
			});

			tl.to(el(".calculating__right-copy.right-copy-3"), {
				autoAlpha: 0,
				duration: 1,
			});
			tl.to(el(".calculating__right-copy.right-copy-4"), {
				autoAlpha: 1,
				duration: 1,
			});

			tl.to(el(".calculating__copy-1"), {autoAlpha: 0});

			tl.to(el(".calculating__copy-2"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-1"), {autoAlpha: 1}, "-=0.5");

			tl.to(el(".calculating__copy-2"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-1"), {autoAlpha: 0}, "-=0.5");

			tl.to(el(".calculating__copy-3"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-2"), {autoAlpha: 1}, "-=0.5");

			tl.to(el(".calculating__copy-3"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-2"), {autoAlpha: 0}, "-=0.5");

			tl.to(el(".calculating__copy-4"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-3"), {autoAlpha: 1}, "-=0.5");

			tl.to(el(".calculating__copy-4"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-3"), {autoAlpha: 0}, "-=0.5");

			tl.to(el(".calculating__copy-5"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-4"), {autoAlpha: 1}, "-=0.5");

			tl.to(el(".calculating__copy-5"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-4"), {autoAlpha: 0}, "-=0.5");

			tl.to(el(".calculating__copy-6"), {xPercent: 0, autoAlpha: 1});
			tl.to(el(".calculating__image-5"), {autoAlpha: 1}, "-=0.5");

			tl.to(el(".calculating__copy-6"), {xPercent: -100, autoAlpha: 0});
			tl.to(el(".calculating__image-5"), {autoAlpha: 0}, "-=0.5");
		},
	});
};
export default timeline_hero;
