import React from "react";
import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import timeline_hero from "./timeline";
import person from "../../../assets/images/person.png";
import coherant from "../../../assets/images/coherent.svg";
import literate from "../../../assets/images/literate.svg";
import on from "../../../assets/images/on.svg";
import self from "../../../assets/images/self.svg";
import ethical from "../../../assets/images/ethical.svg";
import purpleArrow from "../../../assets/images/purple-arrow.svg";
import triangleIcon from "../../../assets/images/shape-2.svg";

const Calculating = () => {
	gsap.registerPlugin(ScrollTrigger);
	// ref for parent to use gsap utility to
	//target class selectors within this element
	const wrapperRef = React.useRef(null);
	const q = gsap.utils.selector(wrapperRef);

	React.useEffect(() => {
		timeline_hero(q, wrapperRef.current);
	}, []);

	return (
		<div className="section__wrapper grid" ref={wrapperRef}>
			<div className="section__column calculating__left-copy-container col-4@lg col-12 calculating__column-1">
				<p className="section__copy calculating__left-copy left-copy-1">
					This is the brand’s Emotional Capital current account.
				</p>

				<p className="section__copy calculating__left-copy left-copy-2">
					This is the brand’s Emotional Capital savings account.
				</p>
			</div>
			<div className="section__column col-4@lg col-12 calculating__container calculating__column-2">
				<div className="calculating__title-container">
					<div className="calculating__title-large">
						Calculating Emotional Capital
					</div>
					<div className="calculating__title-small-container">
						<div className="calculating__title-small title-copy-1">
							<span className="calculating__blue-copy">EMOTOR</span> score
						</div>
						<div className="calculating__title-small title-copy-2">
							<span className="calculating__blue-copy">CLOSE</span> score
						</div>
					</div>
				</div>
				<div className="calculating__image-container">
					<div className="calculating__person-container">
						<img className="calculating__image-person" src={person.src} />
						<div className="calculating__image-category-container">
							<img
								className="calculating__image-category calculating__image-1"
								src={coherant.src}
							/>
							<img
								className="calculating__image-category calculating__image-2"
								src={literate.src}
							/>
							<img
								className="calculating__image-category calculating__image-3"
								src={on.src}
							/>
							<img
								className="calculating__image-category calculating__image-4"
								src={self.src}
							/>
							<img
								className="calculating__image-category calculating__image-5"
								src={ethical.src}
							/>
						</div>
						<div className="calculating__person-middle">
							<div className="calculating__middle-copy-container">
								<div className="calculating__middle-copy middle-copy-1">
									Instinctive
								</div>
								<div className="calculating__middle-copy middle-copy-2">
									Considered
								</div>
							</div>
							<img
								className="calculating__purple-arrow"
								src={purpleArrow.src}
							/>
							<div className="calculating__middle-copy middle-copy-3">
								Appeal
							</div>
						</div>
						<div className="calculating__person-end">
							<img
								className="calculating__triangle-icon"
								src={triangleIcon.src}
							/>
						</div>
					</div>
				</div>
				<div className="calculating__copy-container">
					<div className="calculating__copy calculating__copy-bottom calculating__copy-1">
						“My sort of brand”
						<br />
						<br />
						“I would buy this brand”
					</div>
					<div className="calculating__copy calculating__copy-bottom calculating__copy-2">
						<div className="calculating__copy-bottom-container">
							<span className="calculating__coherant">C</span>oherent
						</div>
						Is the brand consistent throughout the customer experience?
					</div>
					<div className="calculating__copy calculating__copy-bottom calculating__copy-3">
						<div className="calculating__copy-bottom-container">
							<span className="calculating__literate">L</span>iterate
						</div>
						Does the brand understand the needs of its customers?
					</div>
					<div className="calculating__copy calculating__copy-bottom calculating__copy-4">
						<div className="calculating__copy-bottom-container">
							<span className="calculating__on">O</span>n the level
						</div>
						Can the brand be trusted to act with integrity?
					</div>
					<div className="calculating__copy calculating__copy-bottom calculating__copy-5">
						<div className="calculating__copy-bottom-container">
							<span className="calculating__self">S</span>elf-aware
						</div>
						Is the brand honest about its strengths and weaknesses?
					</div>
					<div className="calculating__copy calculating__copy-bottom calculating__copy-6">
						<div className="calculating__copy-bottom-container">
							<span className="calculating__ethical">E</span>thical
						</div>
						Does the brand act ethically?
					</div>
				</div>
			</div>
			<div className="section__column calculating__right-copy-container col-4@lg col-12 calculating__column-3">
				<p className="section__copy calculating__right-copy right-copy-1">
					The Emotor score provides a value for the brand’s instinctive appeal.
				</p>
				<p className="section__copy calculating__right-copy right-copy-2">
					The CLOSE score provides a perspective of the brand’s considered
					appeal.
					<br />
					<br />
					It asks customers to respond to specific emotional attributes of the
					brand.
				</p>
			</div>
		</div>
	);
};

export default Calculating;
