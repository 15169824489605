import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const timeline = (el, parent, props) => {
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.matchMedia({
		"(min-width: 80rem)": () => {
			// desktop screens
			console.log("desktop anim");
			
			ScrollTrigger.create({
				trigger: el(".copy-1"),
				endTrigger: el(".copy-2"),
				start: "center center",
				pin: true,
				id: 'copy1',
				pinSpacing: false,
				markers: false,
				// end: () => `+=${window.innerHeight * 2}`,
			});

			ScrollTrigger.create({
				trigger: el(".flow-chart"),
				endTrigger: el(".copy-2"),
				start: "center center",
				id: 'flow',
				pin: true,
				pinSpacing: false,
				markers: false,
				// end: () => `+=${window.innerHeight}`,
			});

			ScrollTrigger.create({
				trigger: el(".copy-2"),
				start: "center center",
				id: 'copy2',
				pin: true,
				markers: false,
				// end: () => `+=${window.innerHeight}`,
			});

			ScrollTrigger.create({
				trigger: el(".intro__image-container"),
				// endTrigger: el(".copy-5"),
				start: "center center",
				id: "Intro-Container",
				pin: true,
				markers: false,
				end: () => `+=${window.innerHeight * 5}`,			
			})

			ScrollTrigger.create({
				trigger: el(".copy-3"),
				start: "center center",
				id: 'copy3',
				pin: true,
				pinSpacing: false,
				markers: false,
			})

			ScrollTrigger.create({
				trigger: el(".copy-4"),
				start: "center center",
				id: 'copy4',
				pin: true,
				pinSpacing: true,
				markers: false,
			})

			ScrollTrigger.create({
				trigger: el(".copy-5"),
				start: "center center",
				id: 'copy5',
				pin: true,
				pinSpacing: true,
				markers: false,
			})

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: el(".copy-3"),
					pin: false,
					start: "top top",
					end: () => `+=${window.innerHeight}`,
					scrub: true,
				}
			})
			.fromTo(el(".icon-red"), {
				border: "3px solid rgba(240,90,76,0)"
			}, {
				border: "3px solid rgba(240,90,76,1)"
			})

			const tl2 = gsap.timeline({
					scrollTrigger: {
					trigger: el(".copy-4"),
					pin: false,
					start: "top top",
					end: () => `+=${window.innerHeight}`,
					scrub: true,
				}
			})
			.fromTo(el(".icon-green"), {
				border: "3px solid rgba(0,200,125,0)"
			}, {
				border: "3px solid rgba(0,200,125,1)"
			})
			.to(el(".icon-red"), {
				border: "3px solid rgba(240,90,76,0)"
			}, "-=0.5")

			const tl3 = gsap.timeline({
					scrollTrigger: {
					trigger: el(".copy-5"),
					pin: false,
					start: "top top",
					end: () => `+=${window.innerHeight}`,
					scrub: true,
				}
			})
			.to(el(".icon-red"), {
				autoAlpha: 0,
				scale: 0
			})
			.to(el(".icon-1"), {
				x: 30, y: 50, scale: 1.1
			}, 0)
			.to(el(".icon-4"), {
				x:-20, y: 10, scale: 1.15
			}, 0)
			.to(el(".icon-6"), {
				y: -30, x: -30, scale: 1.1
			}, "-=0.5")
		},

		"(max-width: 80rem)": () => {
			////////////////////
			// tablet and mob //
			////////////////////
			console.log("tablet and mobile anim");
		},
	});
};

export default timeline
