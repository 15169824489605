import React from "react";
import Particles from "react-particles-js/index";
import particlesConfig from "../../helpers/particlesConfig";

const ParticlesBackground = (props, ref) => {
	return (
		<div className="animation__particles-wrapper" ref={ref}>
			<Particles
				style={{
					width: "100%",
					height: "100%",
				}}
				params={particlesConfig}
			/>
		</div>
	);
};

export default React.forwardRef(ParticlesBackground);
