import react from 'react'
import Delay from "../../helpers/delay"
import { motion } from "framer-motion"
import variants from "../../helpers/animation"

const Shape = ({ id, src, altText }) => {
  return (
    <Delay delay={id * 300}>
      <motion.img className={`shape-${id}`} src={src} alt={altText} initial="hidden" animate="show" variants={variants.shapes}/>
    </Delay>
  )
}

export default Shape