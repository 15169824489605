import React from "react";
import Graph from "../../../components/Graph"
// packages
import {gsap} from "gsap/dist/gsap";
import timeline from "./timeline";

const Graphs = (props, ref) => {
	const wrapperRef = React.useRef(null);
	const q = gsap.utils.selector(wrapperRef);

	React.useEffect(() => {
		timeline(q, wrapperRef.current, props);
	}, []);

	return (
		<div className="section__graph-wrapper grid" ref={wrapperRef}>
			<div className="section__graph-white-bg graph-1">
				<div className="section__graph-text-wrapper">
					<div className="section__graph-container grid">
          <div className="section__graph-y col-1">
            <div className="section__graph-y-copy copy-y-1">New<br/>Users</div>
						<div className="section__graph-y-copy copy-y-2">Lapsed<br/>Users</div>
            <div className="section__graph-y-copy copy-y-3">Loyalty</div>
            <div className="section__graph-y-copy copy-y-4">Consideration</div>
            <div className="section__graph-y-copy copy-y-5">CLOSE<br/>score</div>
          </div>
				<div className="section__graph col-11 col-9@lg">
						<svg xmlns="http://www.w3.org/2000/svg" className="graph-increase" style={{ position: "absolute" }} width="100%" height="100%" viewBox="0 0 770 477.556">
							<g id="Group_58" data-name="Group 58" transform="translate(-575 -268.444)">
								<path id="Union_7" data-name="Union 7" d="M13,826.482V-14.386H-.075l15-26,15,26H17V826.482Z" transform="translate(1293.492 289.308) rotate(59)" fill="#00c87d"/>
							</g>
						</svg>
						<svg xmlns="http://www.w3.org/2000/svg" className="graph-decrease" width="100%" height="100%" viewBox="0 0 770 462">
							<g id="Group_59" data-name="Group 59" transform="translate(-575 -338)">
								<g id="Group_53" data-name="Group 53" transform="translate(43)">
									<rect id="Rectangle_39" data-name="Rectangle 39" width="4" height="462" transform="translate(532 338)" fill="#292c38"/>
									<rect id="Rectangle_40" data-name="Rectangle 40" width="4" height="770" transform="translate(1302 796) rotate(90)" fill="#292c38"/>
								</g>
								<path id="Union_6" className="line" data-name="Union 6" d="M13,826.482V26H0L15,0,30,26H17V826.482Z" transform="translate(1328.402 738.686) rotate(119)" fill="#f05a4c"/>
							</g>
						</svg>
				</div>
        <div className="section__graph-x col-start-2 col-start-1@lg">
          <div className="section__graph-x-copy copy-x-1">Emotor Score</div>
        </div>
    </div>				
					<div className="section__graph-copy-container">
						<p className="section__graph-copy copy-1">
							The higher the emotor score, the greater the number of new customers a brand can attract.
						</p>
						<p className="section__graph-copy copy-2">
							The lower the emotor score, the greater the number of lapsed users a brand will have.
						</p>
						<p className="section__graph-copy copy-3">
							A brand’s emotor score correlates with customer loyalty.
						</p>
						<p className="section__graph-copy copy-4">
							And within market consideration.
							High levels of Emotional Capital ensures the brand is on the shortlist.
						</p>
						<p className="section__graph-copy copy-5">
							Emotor directly correlates with a brand’s CLOSE scores.
							So, to earn more Emotional Capital and benefit from closer
							customer relationships, a brand needs to increase its 
							CLOSE scores.
						</p>
					</div>
				</div>			
			</div>
			<div className="section__graph-white-bg graph-6">
				<div className="section__graph-text-wrapper">
					<div className="section__graph-copy-container">
						<p className="section__graph-copy copy-6">
							A brand’s CLOSE score will identify where it struggles
							to emotionally connect with potential customers...
						</p>
					</div>
					<div className="section__graph-container">
						<div className="section__graph">
							<svg xmlns="http://www.w3.org/2000/svg" width="770" height="548" viewBox="0 0 770 548">
								<g id="Group_61" data-name="Group 61" transform="translate(-575 -338)">
									<rect id="Rectangle_45" data-name="Rectangle 45" width="138" height="69" transform="translate(582 817)" fill="#fff"/>
									<g id="Group_60" data-name="Group 60">
										<g id="Group_53" data-name="Group 53" transform="translate(43)">
											<rect id="Rectangle_39" data-name="Rectangle 39" width="4" height="462" transform="translate(532 338)" fill="#292c39"/>
											<rect id="Rectangle_40" data-name="Rectangle 40" width="4" height="770" transform="translate(1302 796) rotate(90)" fill="#292c39"/>
										</g>
										<rect id="Rectangle_50" data-name="Rectangle 50" width="130" height="399" transform="translate(586 395)" fill="#00c87d"/>
										<rect id="Rectangle_53" data-name="Rectangle 53" width="130" height="336" transform="translate(1051 458)" fill="#00c87d"/>
										<rect id="Rectangle_54" data-name="Rectangle 54" width="130" height="262" transform="translate(1206 532)" fill="#00c87d"/>
										<rect id="Rectangle_51" data-name="Rectangle 51" width="130" height="109" transform="translate(741 685)" fill="#f05a4c"/>
										<rect id="Rectangle_52" data-name="Rectangle 52" width="130" height="61" transform="translate(896 733)" fill="#f05a4c"/>
									</g>
									<rect id="Rectangle_46" data-name="Rectangle 46" width="138" height="69" transform="translate(1203 817)" fill="#fff"/>
									<rect id="Rectangle_47" data-name="Rectangle 47" width="138" height="69" transform="translate(1048 817)" fill="#fff"/>
									<rect id="Rectangle_48" data-name="Rectangle 48" width="138" height="69" transform="translate(893 817)" fill="#fff"/>
									<rect id="Rectangle_49" data-name="Rectangle 49" width="138" height="69" transform="translate(737 817)" fill="#fff"/>
									<text id="Coherent" transform="translate(651 857)" fill="#292c39" fontSize="16" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="-39" y="0">Coherent</tspan></text>
									<text id="Literate" transform="translate(806 857)" fill="#292c39" fontSize="16" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="-32" y="0">Literate</tspan></text>
									<text id="Ethical" transform="translate(1272 857)" fill="#292c39" fontSize="16" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="-28" y="0">Ethical</tspan></text>
									<text id="On_the_level" data-name="On the level" transform="translate(910 843)" fill="#292c39" fontSize="16" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="0" y="15">On the level</tspan></text>
									<text id="Self-aware" transform="translate(1071 843)" fill="#292c39" fontSize="16" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700"><tspan x="0" y="15">Self-aware</tspan></text>
								</g>
							</svg>
						</div>
					</div>
					<div className="section__graph-copy-container">
						<p className="section__graph-copy copy-7">
							...providing a clear focus for marketers looking
							to improve their brand experience across the
							customer journey.
						</p>
					</div>
				</div>			
			</div>
		</div>
	);
};

export default React.forwardRef(Graphs);
