import React from "react";

function index() {
	return (
		<div className="overlay__wrapper">
			<div className="overlay__all-message">
				For the best experience, please view on desktop
			</div>
		</div>
	);
}

export default index;
