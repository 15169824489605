import react, { useState } from 'react'
import ReactPlayer from 'react-player'
import { motion } from 'framer-motion'
import Spinner from "../../components/Spinner"
import playIcon from "../../assets/images/play-icon.svg"

const VideoPlayer = ({ url, title, isPlayerOpen }) => {
  const [videoLoading, setVideoLoading] = useState(true)

  const spinner = () => {
    setVideoLoading(!videoLoading)
  }

  return (
    <div className="video-player__container">
      {videoLoading ? (
        <Spinner />
      ) : null} 
        <ReactPlayer
          className="video-player"
          url={url}
          playing
          controls
          onStart={() => setVideoLoading(false)}
          width='100%'
          height='100%'
        />
    </div>
  )
}

export default VideoPlayer