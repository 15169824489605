import React, {useRef, forwardRef} from "react";
import {motion} from "framer-motion"
// packages
import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

import ScrollButton from "../../ScrollButton"
import person from "../../../assets/images/person.png";

import circle from "../../../assets/images/shape-1.svg";
import triangle from "../../../assets/images/shape-2.svg";
import square from "../../../assets/images/shape-3.svg";
import star from "../../../assets/images/shape-4.svg";
import cross from "../../../assets/images/shape-5.svg";

import greenArrow from "../../../assets/images/green-arrow.svg";
import redArrow from "../../../assets/images/red-arrow.svg";

import timeline_s1 from "./timeline";

function Intro(props, ref) {
	gsap.registerPlugin(ScrollTrigger);
	// ref for parent to use gsap utility to
	//target class selectors within this element
	const wrapperRef = useRef(null)
	const q = gsap.utils.selector(wrapperRef);

	// run timeline for section 1
	React.useEffect(() => {
		timeline_s1(q);
	}, []);

	return (
		<div className="intro__wrapper grid" ref={wrapperRef}>
			<div className="section__column col-12 col-4@lg copy-1">
				<p className="section__copy">We live in a world filled with noise.</p>
			</div>
			<div className="section__column section__pin-column col-12 col-4@lg">
				<div className="intro__image-container">
					<img className="intro__person" src={person.src} />
						<div className="intro__icon-container">
							<img className="intro__icons icon-1" src={circle.src} />
							<img className="intro__icons icon-2" src={triangle.src} />
							<img className="intro__icons icon-3" src={square.src} />
							<img className="intro__icons icon-4" src={cross.src} />
							<img className="intro__icons icon-5" src={star.src} />
						</div>
					</div>
				</div>
				<ScrollButton />
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg copy-2">
				<p className="section__copy">
					Every day, hundreds if not thousands of brands compete for our
					attention.
				</p>
			</div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg copy-3">
				<p className="section__copy">
					To get through the day with our sanity intact we filter the messages
					we notice and respond to.
				</p>
			</div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg copy-4">
				<p className="section__copy">
					We push away the brands we don’t trust or emotionally connect with.
				</p>
			</div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg copy-5">
				<p className="section__copy">
					And we pull towards us the brands we do connect with.
				</p>
			</div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg copy-6">
				<p className="section__copy ">
					Brands with close customer relationships have a positive supply of
					Emotional Capital.
				</p>
			</div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg copy-7">
				<p className="section__copy ">
					Emotional Capital is the accumulation of associations and intuitions
					about the brand that are built over time.
				</p>
			</div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg intro__green-arrow">
				<div className="intro__arrow-container green-arrow">
					<p className="section__copy">+VE</p>
					<img className="intro__arrow" src={greenArrow.src} />
					<p className="section__copy">Emotional Capital</p>
				</div>
			</div>
			{/* next row */}
			<div className="section__column col-12 col-4@lg intro__red-arrow">
				<div className="intro__arrow-container red-arrow">
					<p className="section__copy">-VE</p>
					<img className="intro__arrow" src={redArrow.src} />
					<p className="section__copy">Emotional Capital</p>
				</div>
			</div>
			<div className="section__column col-12 col-4@lg section__column-spacer"></div>
			<div className="section__column col-12 col-4@lg copy-8">
				<p className="section__copy">
					Brands without close customer relationships, those unheard and rarely
					seen, have a negative supply of Emotional Capital. They actively repel
					potential customers.
				</p>
			</div>
		</div>
	);
}

export default forwardRef(Intro);
