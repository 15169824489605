import React, {useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {NextSeo} from "next-seo";
import VideoPlayer from "../../Video";
import Modal from "../../Modal";
import Particles from "../..//ParticlesBackground";
import playIcon from "../../../assets/images/play-icon.svg";
import Shape from "../../Shape";
import circle from "../../../assets/images/shape-1.svg";
import triangle from "../../../assets/images/shape-2.svg";
import square from "../../../assets/images/shape-3.svg";
import cross from "../../../assets/images/shape-4.svg";
import star from "../../../assets/images/shape-5.svg";
import hexagon from "../../../assets/images/shape-6.svg";

const Videos = () => {
	const [modal, setModal] = useState(false);
	const [modalTwo, setModalTwo] = useState(false);

	const open = () => setModal(true);
	const close = () => setModal(false);
	const openTwo = () => setModalTwo(true);
	const closeTwo = () => setModalTwo(false);

	const shapes = [
		{id: 1, src: circle.src, alt: ""},
		{id: 2, src: triangle.src, alt: "Green outline of a triangle"},
		{id: 3, src: square.src, alt: "Blue outline of a square"},
		{id: 4, src: cross.src, alt: "Red cross"},
		{id: 5, src: star.src, alt: "Yellow outline of a star"},
		{id: 6, src: hexagon.src, alt: "Grey outline of a hexagon"},
	];

	return (
		<>
			<div id="watchOurStory" className="layout__section-flex">
				<div className="shape__layout-container">
					<motion.div className="shape__items-container">
						{shapes.map((s) => (
							<Shape key={s.id} id={s.id} src={s.src} alt={s.alt} />
						))}
					</motion.div>
					<motion.button
						whileHover={{scale: 1.02}}
						data-modal="modal-one"
						whileTap={{scale: 0.9}}
						className="button video-player__button"
						onClick={() => (modal ? close() : open())}>
						<span className="button__text-line-1">Get</span>
						<span className="icon">
							<img src={playIcon.src} />
						</span>
						<span className="button__text-line-2">Closer</span>
					</motion.button>
					<motion.button
						whileHover={{scale: 1.02}}
						data-modal="modal-two"
						whileTap={{scale: 0.9}}
						className="button video-player__button"
						onClick={() => (modalTwo ? closeTwo() : openTwo())}>
						<span className="button__text-line-1">Our</span>
						<span className="icon">
							<img src={playIcon.src} />
						</span>
						<span className="button__text-line-2">Work</span>
					</motion.button>
				</div>

				<AnimatePresence
					initial={false}
					exitBeforeEnter={true}
					onExitComplete={() => null}>
					{modal && (
						<Modal modalOpen={modal} handleClose={close}>
							<VideoPlayer url={"https://vimeo.com/651600063"} />
						</Modal>
					)}
					{modalTwo && (
						<Modal modalOpen={modalTwo} handleClose={closeTwo}>
							<VideoPlayer url={"https://vimeo.com/652003613"} />
						</Modal>
					)}
				</AnimatePresence>
			</div>
		</>
	);
};

export default Videos;
