import React, {useRef, useState} from "react";

// components
import Layout from "../components/Layout/layout";
import Particles from "../components/ParticlesBackground";
import Hero from "../components/animation/Hero";
import Intro from "../components/animation/Intro";
import Calculating from "../components/animation/Calculating";
import Flow from "../components/animation/Flow";
import Actions from "../components/animation/Actions";
import Videos from "../components/animation/Videos";
import Contact from "../components/animation/Contact";
import Overlay from "../components/Overlay";

// packages
import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import Graphs from "../components/animation/Graphs";
import {NextSeo} from "next-seo";

const Home = () => {
	gsap.registerPlugin(ScrollTrigger);
	const particlesBg = useRef(null);
	const calculating = useRef(null);
	const intro = useRef(null);

	const [isLight, setLight] = useState(false);

	// React.useEffect(() => {
	// 	gsap.to(particlesBg.current, {
	// 		scrollTrigger: {
	// 			pinSpacing: false,
	// 			endTrigger: calculating.current,
	// 			scrub: true,
	// 			id: "bg",
	// 			start: "top top",
	// 		},
	// 		y: () => -window.innerHeight,
	// 	});
	// }, []);

	const scrollToSection = (ref) => {
		window.scrollTo(0, ref.current.offsetTop);
	};

	return (
		<>
			<NextSeo title={"MSQ Emotional Capital"} />
			<Particles ref={particlesBg} />
			<Layout isLogoLight={isLight}>
				<Overlay />
				<div className="animation__container grid">
					<Intro />
					<Hero />
					<Calculating />
					<Graphs isLogoLight={isLight} setLogoLight={setLight} />
					<Flow />
					<Actions isLogoLight={isLight} setLogoLight={setLight} />
					<Videos />
					<Contact />
				</div>
			</Layout>
		</>
	);
};

export default Home;
