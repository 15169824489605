import react from 'react'
import {motion} from 'framer-motion'

const spinnerContainer = {
  start: {
    transition: {
      staggerChildren: 0.1
    }
  },
  end: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

const spinnerCircle = {
  start: {
    y: '0%'
  },
  end: {
    y: '100%'
  }
}

const spinnerCircleTransition = {
  duration: 0.4,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "easeInOut"
}

const Spinner = () => {
  return (
    <motion.div className="spinner__wrapper" variants={spinnerContainer} initial="start" animate="end">
      <motion.span className="spinner__circle" variants={spinnerCircle} transition={spinnerCircleTransition}></motion.span>
      <motion.span className="spinner__circle" variants={spinnerCircle} transition={spinnerCircleTransition}></motion.span>
      <motion.span className="spinner__circle" variants={spinnerCircle} transition={spinnerCircleTransition}></motion.span>
    </motion.div>
  )
}

export default Spinner