import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const timeline_s1 = (el) => {
	gsap.registerPlugin(ScrollTrigger);
	gsap.set(el(".intro__icons"), {autoAlpha: 0});
	gsap.set(el(".pulse-before, .pulse-after"), {opacity: 0, scale: 0.5});

	ScrollTrigger.matchMedia({
		"(min-width: 80rem)": () => {
			// desktop screens
			console.log("desktop anim");

			ScrollTrigger.defaults({
				markers: false,
				pin: true,
				scrub: true,
				anticipatePin: 1,
			});

			ScrollTrigger.create({
				trigger: el(".intro__image-container"),
				start: "center center",
				end: () => `+=${window.innerHeight * 12}`,
				pin: true,
			});

			ScrollTrigger.create({
				trigger: el(".copy-1"),
				start: "center center",
				endTrigger: el(".copy-2"),
				pinSpacing: false,
			});

			ScrollTrigger.create({
				trigger: el(".copy-2"),
				start: "center center",
				endTrigger: el(".copy-2"),
			});

			ScrollTrigger.create({
				trigger: el(".copy-3"),
				endTrigger: el(".copy-4"),
				start: "center center",
				pinSpacing: false,
			});

			ScrollTrigger.create({
				trigger: el(".copy-4"),
				start: "center center",
			});

			ScrollTrigger.create({
				trigger: el(".copy-5"),
				start: "center center",
			});

			gsap
				.timeline({
					scrollTrigger: {
						trigger: el(".section__pin-column"),
						pin: false,
						start: "top top",
						end: () => `+=${window.innerHeight}`,
						scrub: true,
					},
				})
				.to(el(".intro__icons"), {
					autoAlpha: 1,
					stagger: 1,
					staggerChildren: 1,
				});

			// move icons away
			gsap
				.timeline({
					scrollTrigger: {
						trigger: el(".copy-4"),
						pin: false,
						start: "top top",
						end: () => `+=${window.innerHeight}`,
						scrub: true,
					},
				})
				.to(
					el(".icon-1"),
					{
						x: -100,
						y: -100,
						autoAlpha: 0.25,
						scale: 0.75,
					},
					0
				)
				.to(
					el(".icon-3"),
					{
						x: -100,
						y: 100,
						autoAlpha: 0.25,
						scale: 0.75,
					},
					0
				)
				.to(
					el(".icon-5"),
					{
						x: 100,
						y: -100,
						autoAlpha: 0.25,
						scale: 0.75,
					},
					"+=0.25"
				);

			// move icons towards
			gsap
				.timeline({
					scrollTrigger: {
						trigger: el(".copy-5"),
						pin: false,
						start: "top top",
						end: () => `+=${window.innerHeight}`,
						scrub: true,
					},
				})
				.to(
					el(".icon-2"),
					{
						x: -50,
						y: -100,
						scale: 1.25,
					},
					0
				)
				.to(
					el(".icon-4"),
					{
						x: -100,
						y: -50,
						scale: 1.25,
					},
					0
				);

			// pin green arrow
			ScrollTrigger.create({
				trigger: el(".green-arrow"),
				start: "center center",
				endTrigger: el(".copy-7"),
			});

			// pin copy for green arrows
			ScrollTrigger.create({
				trigger: el(".copy-6"),
				start: "center center",
				endTrigger: el(".copy-7"),
				pinSpacing: false,
			});

			// pin copy for green arrows
			ScrollTrigger.create({
				trigger: el(".copy-7"),
				start: "center center",
				pinSpacing: false,
			});
			// pin copy for green arrows
			ScrollTrigger.create({
				trigger: el(".copy-8"),
				start: "center center",
				end: () => `+=${window.innerHeight}`,
				pinSpacing: true,
			});

			// pin green arrow
			ScrollTrigger.create({
				trigger: el(".red-arrow"),
				start: "center center",
				end: () => `+=${window.innerHeight}`,
				pinSpacing: false,
			});
		},
		"(max-width: 80rem)": () => {
			////////////////////
			// tablet and mob //
			////////////////////
			console.log("tablet and mobile anim");

			ScrollTrigger.defaults({
				markers: false,
				pin: true,
				scrub: true,
				anticipatePin: 1,
			});

			ScrollTrigger.create({
				trigger: el(".intro__image-container"),
				start: "center 25%",
				end: () => `+=${window.innerHeight * 19}`,
				pin: true,
			});

			ScrollTrigger.create({
				trigger: el(".copy-1"),
				start: "center 75%",
			});

			ScrollTrigger.create({
				trigger: el(".copy-2"),
				start: "center 75%",
			});

			ScrollTrigger.create({
				trigger: el(".copy-3"),
				start: "center 75%",
			});

			ScrollTrigger.create({
				trigger: el(".copy-4"),
				start: "center 75%",
			});

			ScrollTrigger.create({
				trigger: el(".copy-5"),
				start: "center 75%",
			});

			gsap
				.timeline({
					scrollTrigger: {
						trigger: el(".section__pin-column"),
						pin: false,
						start: "center 25%",
						id: "pin icons",
						end: () => `+=${window.innerHeight}`,
						scrub: true,
					},
				})
				.to(el(".intro__icons"), {
					autoAlpha: 1,
					stagger: 1,
				});

			// move icons away
			gsap
				.timeline({
					scrollTrigger: {
						trigger: el(".copy-3"),
						pin: false,
						start: "center 25%",
						end: () => `+=${window.innerHeight}`,
						scrub: true,
					},
				})
				.to(el(".icon-1"), {
					x: -100,
					y: -100,
					autoAlpha: 0.25,
				})
				.to(el(".icon-3"), {
					x: -100,
					y: 100,
					autoAlpha: 0.25,
				})
				.to(el(".icon-5"), {
					x: 100,
					y: -100,
					autoAlpha: 0.25,
				});

			// move icons towards
			gsap
				.timeline({
					scrollTrigger: {
						trigger: el(".copy-4"),
						pin: false,
						start: "center 25%",
						end: () => `+=${window.innerHeight}`,
						scrub: true,
					},
				})
				.to(el(".icon-2"), {
					x: -50,
					y: -100,
				})
				.to(el(".icon-4"), {
					x: -100,
					y: -50,
				});

			// pin green arrow
			ScrollTrigger.create({
				trigger: el(".green-arrow"),
				start: "center 75%",
				endTrigger: el(".copy-8"),
			});

			// pin copy for green arrows
			ScrollTrigger.create({
				trigger: el(".copy-6"),
				start: "center 75%",
				endTrigger: el(".copy-7"),
			});

			// pin copy for green arrows
			ScrollTrigger.create({
				trigger: el(".copy-7"),
				start: "center 75%",
				end: () => `+=${window.innerHeight}`,
			});

			// pin green arrow
			ScrollTrigger.create({
				trigger: el(".red-arrow"),
				start: "center 75%",
				end: () => `+=${window.innerHeight}`,
			});
		},
	});
};

export default timeline_s1;
