import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const timeline_hero = (el, parent) => {
	gsap.registerPlugin(ScrollTrigger);

	console.log(parent);

	gsap.set(el(".hero__title-line-1"), {
		autoAlpha: 0,
	});

	gsap.set(el(".hero__title-line-2"), {
		autoAlpha: 0,
	});

	console.log(el());

	gsap
		.timeline({
			scrollTrigger: {
				trigger: parent,
				markers: false,
				id: "hero-wrapper-pin",
				pin: true,
				scrub: true,
				start: "top top",
			},
		})
		.to(el(".hero__title-line-1"), {autoAlpha: 1})
		.to(el(".hero__title-line-2"), {autoAlpha: 1});
};

export default timeline_hero;
