import react from 'react'
import {motion} from 'framer-motion'
import Background from "./background"
import closeIcon from "../../assets/images/close-icon.svg"

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      transitionDuration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500
    }
  },
  exit: {
    y: "100vh",
    opacity: 0
  }
}

const Modal = ({ handleClose, children }) => {
  return (
    <Background onClick={handleClose}>
      <motion.div className="modal" onClick={(e) => e.stopPropagation()} variants={dropIn} initial="hidden" animate="visible" exit="exit">
        {children}
        <button className="modal__close-button" onClick={handleClose}>
          <svg width="23" height="23" viewBox="0 0 19 19"><path fill="transparent" strokeWidth="2" stroke="#292C39" strokeLinecap="round" d="M 3 16.5 L 17 2.5"></path><path fill="transparent" strokeWidth="2" stroke="#292C39" strokeLinecap="round" d="M 2 9.423 L 20 9.423" opacity="0"></path><path fill="transparent" strokeWidth="2" stroke="#292C39" strokeLinecap="round" d="M 3 2.5 L 17 16.346"></path></svg>
        </button>
      </motion.div>
    </Background>
  )
}

export default Modal